<template>
  <div>
    <b-card>
      <b-row>
        <b-col md="6">
          <user-search
            v-model="userId"
            placeholder="Me"
            @input="$emit('input', userId)"
          />
        </b-col>
        <b-col md="6">
          <b-form-select
            v-model="year"
            :options="yearRange"
          />
        </b-col>
      </b-row>
    </b-card>
    <b-row id="tour-attendance">
      <b-col
        v-for="i in 12"
        :key="i"
        md="3"
      >
        <month-blocks
          :year="year"
          :month="i"
          :processing="processing"
          :present-days="presentDays"
          :present-days-duration="presentDaysDuration"
          :user-id="userId"
          :class="{
            'bg-light-success px-2 py-1': i === thisMonth
          }"
        />
      </b-col>
    </b-row>
  </div>
</template>
<script>
import { BCard, BFormSelect } from 'bootstrap-vue'
import UserSearch from '@/views/common/components/UserSearch.vue'
import { getUserData } from '@/auth/utils'
import useApollo from '@/plugins/graphql/useApollo'
import { mapActions, mapGetters } from 'vuex'
import MonthBlocks from './MonthBlocks.vue'
import { setComponentReady } from '../tour/tour'

export default {
  components: {
    MonthBlocks,
    BCard,
    UserSearch,
    BFormSelect,
  },
  data() {
    const thisYear = (new Date()).getFullYear()
    const yearRange = []
    for (let i = 2022; i <= thisYear; i += 1) {
      yearRange.push(i)
    }
    const defaultUser = getUserData().uuid
    return {
      year: thisYear,
      userId: defaultUser,
      yearRange,
      thisMonth: Number(this.TODAY({ format: 'MM' })),
      presentDays: {},
      presentDaysDuration: {},
      processing: false,
    }
  },
  computed: {
    ...mapGetters('project', ['GET_SELECTED_USER']),
  },
  watch: {
    year: {
      handler() {
        this.getDailyActivityRecords()
      },
      immediate: true,
    },
    userId: {
      handler(newValue) {
        if (newValue) {
          this.getDailyActivityRecords()
          if (this.GET_SELECTED_USER !== newValue) this.setSelectedUser(newValue)
        }
      },
    },
    GET_SELECTED_USER: {
      handler(val) {
        if (val && val !== this.userId) this.userId = val
      },
      immediate: true,
    },
  },
  mounted() {
    setComponentReady()
  },
  methods: {
    ...mapActions('project', ['setSelectedUser']),
    getDailyActivityRecords() {
      if (this.processing) {
        return
      }
      this.processing = true
      const startTime = `${this.year}-01-01 00:00:00`
      const endTime = this.ADD_DAYS(`${this.year}-12-31 00:00:00`, 1, 'YYYY-MM-DD 00:00:00').start
      useApollo.activities.getDailyActivities({
        startTime,
        endTime,
        projectId: this.$store.state.project.selectedProject,
        userId: this.userId,
        first: -1,
      }).then(response => {
        const presentDays = {}
        const presentDaysDuration = {}
        const activities = response.data.user?.dailyActivities.data ?? []
        activities.forEach(item => {
          presentDays[item.date] = true
          presentDaysDuration[item.date] = item.duration
        })
        this.presentDays = presentDays
        this.presentDaysDuration = presentDaysDuration
      }).catch(err => {
        console.error(err)
      }).finally(() => { this.processing = false })
    },
  },
}
</script>
